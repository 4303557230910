import { isFeatureEnable, FeatureTypes } from '@common/lib/featureToggle';
import { getLocaleEndDateString } from '@common/lib/getLocaleDate';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import GiftWalletButton from './GiftWalletButton';
import Ticket from './Ticket';
import type { GiftCardTickets as GiftCardTicketsType } from '../api/graphql/getGiftCardGql';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  root: {
    maxWidth: 600
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(),
    paddingLeft: theme.spacing(2)
  },
  titleMain: {
    fontWeight: 'bold'
  },
  tickets: {
    width: '90%'
  },
  giftWalletButton: {
    margin: `${theme.spacing(2)} 0 ${theme.spacing()} 0`
  }
});

interface GiftCardTicketsOwnProps {
  giftCardTickets: GiftCardTicketsType;
  giftWalletStorable: boolean;
  handleTicketClick: Function;
  handleGiftWalletButtonClick?: () => void;
}

type GiftCardTicketsProps = GiftCardTicketsOwnProps & WithStyles<typeof styles>;

const GiftCardTickets: React.FC<GiftCardTicketsProps> = ({
  classes,
  handleTicketClick,
  giftCardTickets,
  giftWalletStorable,
  handleGiftWalletButtonClick
}) => (
  <Grid
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
    className={classes.root}
  >
    <Grid item container justifyContent="flex-start" className={classes.title}>
      <Typography variant="h6" className={classes.titleMain}>
        チケット一覧
      </Typography>
    </Grid>
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      className={classes.tickets}
      data-cy="giftCardTickets"
    >
      {!giftCardTickets
        ? null
        : giftCardTickets.map((ticket, i) => {
            // 単一ギフト or 選択済みの選べるギフト
            if (ticket.gift) {
              return (
                <Ticket
                  ticket={ticket}
                  expiresAt={ticket.gift.expiresAt}
                  handleClick={() => handleTicketClick(ticket)}
                  key={i}
                />
              );
            }
            return (
              <Ticket
                ticket={ticket}
                expiresAt={
                  !!ticket.choosingOption
                    ? getLocaleEndDateString(
                        ticket.choosingOption.choosableEndAt
                      )
                    : undefined
                }
                beginsAt={ticket.choosingOption?.choosableBeginAt}
                handleClick={() => handleTicketClick(ticket)}
                key={i}
              />
            );
          })}
    </Grid>
    {giftWalletStorable ? (
      <Grid
        item
        container
        justifyContent="center"
        className={classes.giftWalletButton}
      >
        <GiftWalletButton
          handleGiftWalletButtonClick={handleGiftWalletButtonClick}
          text="ギフトを保存する"
        />
      </Grid>
    ) : null}
  </Grid>
);

export default withStyles(styles)(GiftCardTickets);
