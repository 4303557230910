import { Drawer, ListItem, List, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { AccountContents } from '../MainContents';
import type {
  DrawerPropsMappedFromDispatch,
  DrawerPropsMappedFromState
} from '../../containers/account/DrawerContainer';
import type { Theme } from '@mui/material';
import type { WithStyles, StyleRules } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
  drawer: {
    flexShrink: 0
  },
  list: {
    marginTop: 100
  },
  link: {
    textDecoration: 'none',
    color: 'unset'
  },
  linkText: {
    paddingLeft: theme.spacing()
  },
  selected: {
    borderLeft: `solid 2px ${theme.palette.primary.main}`,
    marginLeft: -2,
    fontWeight: 700
  }
});

type DrawerProps = DrawerPropsMappedFromDispatch &
  DrawerPropsMappedFromState &
  WithStyles<typeof styles>;

const AccountDrawer: React.FC<DrawerProps> = ({
  classes,
  distributionPartner,
  distributionPartnerUser
}) => {
  const location = useLocation();
  const isSelectedPath = (content: string): boolean =>
    location.pathname.includes(content);

  const isDistributionPartnerPath = (): boolean =>
    isSelectedPath(`console/${AccountContents.DISTRIBUTION_PARTNERS}`);

  const isDistributionPartnerUserPath = (): boolean =>
    isSelectedPath(`console/${AccountContents.USERS}`);

  return (
    <Drawer
      variant="persistent"
      className={classes.drawer}
      anchor="left"
      open
      classes={{
        paper: classes.drawerPaper
      }}
      data-cy="drawer"
    >
      <List className={classes.list}>
        <Link
          to={`/console/${AccountContents.DISTRIBUTION_PARTNERS}/${distributionPartner.urlCode}`}
          className={classes.link}
        >
          <ListItem button data-cy="linkToDistributionPartner">
            <Typography
              variant="body2"
              className={clsx(classes.linkText, {
                [classes.selected]: isDistributionPartnerPath()
              })}
            >
              企業情報
            </Typography>
          </ListItem>
        </Link>
        <Link
          to={`/console/${AccountContents.USERS}/${distributionPartnerUser.urlCode}`}
          className={classes.link}
        >
          <ListItem button data-cy="linkToDistributionPartner">
            <Typography
              variant="body2"
              className={clsx(classes.linkText, {
                [classes.selected]: isDistributionPartnerUserPath()
              })}
            >
              ユーザー情報
            </Typography>
          </ListItem>
        </Link>
      </List>
    </Drawer>
  );
};

export default withStyles(styles)(AccountDrawer);
