import { createSelector } from 'reselect';
import type { ContentImageUrl } from './getProjectDraftsForDisplay';
import type { AppState } from '../reducers';

import type { ProjectState } from '../reducers/projects';

const getProjects = (state: AppState) => state.projects;

export type LocalProjectForDisplay = ProjectState & {
  contentImageUrls: ContentImageUrl[];
} & { isMypageProject: boolean };

const getConvertedProjectsForDisplay = createSelector(
  [getProjects],
  (projects): LocalProjectForDisplay[] => {
    const projectsForDisplay = projects.map(project => {
      const giftCardConfigContentsImageUrls =
        getGiftCardConfigContentsImageUrls(project.giftCardConfigs);
      const gifteeBoxConfigContentsImageUrls =
        getGifteeBoxConfigContentsImageUrls(project.gifteeBoxConfigs || []);
      return {
        ...project,
        contentImageUrls: [
          ...giftCardConfigContentsImageUrls.flat(),
          ...gifteeBoxConfigContentsImageUrls
        ],
        // TODO: my page 案件であることを project.messageThread で判定する。厳密に行う場合は internal api を修正して state として扱う。
        isMypageProject: project.messageThread ? false : true
      };
    });
    return projectsForDisplay;
  }
);

export const getProjectsForDisplay = createSelector(
  [getConvertedProjectsForDisplay],
  (convertedProjects): LocalProjectForDisplay[] =>
    convertedProjects.filter(convertedProject => !convertedProject.isSample)
);

export const getSampleProjectsForDisplay = createSelector(
  [getConvertedProjectsForDisplay],
  (convertedProjects): LocalProjectForDisplay[] =>
    convertedProjects.filter(convertedProject => convertedProject.isSample)
);

const getGiftCardConfigContentsImageUrls = (
  giftCardConfigs: ProjectState['giftCardConfigs']
) =>
  giftCardConfigs.map(giftCardConfig =>
    getGiftCardTicketConfigsImageUrls(giftCardConfig.giftCardTicketConfigs)
  );

const getGifteeBoxConfigContentsImageUrls = (
  gifteeBoxConfigs: ProjectState['gifteeBoxConfigs']
) =>
  gifteeBoxConfigs.map(
    gifteeBoxConfig => gifteeBoxConfig.gifteeBoxTemplate.imageUrl
  );

const getGiftCardTicketConfigsImageUrls = (
  giftCardTicketConfigs: ProjectState['giftCardConfigs'][number]['giftCardTicketConfigs']
) =>
  giftCardTicketConfigs.reduce((imageUrls, giftCardTicketConfig) => {
    giftCardTicketConfig.fixedContentOption;
    if (!!giftCardTicketConfig.fixedContentOption) {
      return [
        ...imageUrls,
        giftCardTicketConfig.fixedContentOption.content.imageUrl
      ];
    }
    return [
      ...imageUrls,
      ...giftCardTicketConfig.choosableContentOption.choices.map(
        choice => choice.content.imageUrl
      )
    ];
  }, []);
